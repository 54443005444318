import * as React from "react";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import i18n from "../components/i18n";
import { Trans, withTranslation, useTranslation } from "react-i18next";
import Layout from "../components/layout";
import JoinUsListBoxCard from "../components/joinus_list_box_card";
import NavigationHelper from "../helpers/navigation";
import Seo from "../components/seo";

/** Images import */
import img_joinus_banner from "../images/a1_cloud_hiring_desktop_banner_bg.webp";

// markup
const JoinUsPage = (props) => {
  const [activeLanguage, setActiveLanguage] = useState();
  useEffect(() => {
    setActiveLanguage(i18n.language);
  }, [setActiveLanguage]);

  const { t } = useTranslation(["main", "forms"]);

  const ExtJoinUsCard = withTranslation("main")(JoinUsListBoxCard);

  const langURLKey = `./`;
  const servicesURLKey = `${langURLKey}services`;

  // Navigation info
  const pageInfo = {
    key: "join",
    lang: i18n.language,
  };

  const job_offers_slider_settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };

  return (
    <Layout location={props.location} pageInfo={pageInfo}>
      <Helmet
        bodyAttributes={{
          class: "rejoindre-page",
        }}
      >
        <style type="text/css">{`
            .main-content {overflow: initial;}
          `}</style>
      </Helmet>

      <Seo
        description={t("main:joinus-meta_seo-description_txt")}
        title={t("main:joinus-meta_seo-title_txt")}
        keywords={[
          t("main:joinus-meta_seo-keyword1_txt"),
          t("main:joinus-meta_seo-keyword2_txt"),
          t("main:joinus-meta_seo-keyword3_txt"),
          t("main:joinus-meta_seo-keyword4_txt"),
        ]}
        url={props.location.href}
        image_url={`${props.location.origin}/a1_cloud_logo.png`}
      />

      {/* breadcumbs */}
      <div className="site-breadcumbs">
        <div className="container">
          <small>
            <a
              href={NavigationHelper.useGeneratePageURL(activeLanguage, "home")}
              title={t("main:global_header_home_menu-item-seo-title_txt")}
            >
              A1:
            </a>{" "}
            // {t("main:joinus_main_breadcrumb_title")}
          </small>
        </div>
      </div>
      {/* end breadcumbs */}

      <div className="rejoindre-banner">
        <div
          className="service_header"
          style={{ backgroundImage: `url(${img_joinus_banner})` }}
        >
          <div className="container">
            <div className="title_block">
              <h1 className="nos-service">
                {t("main:joinus_main_content_title")}
              </h1>
            </div>
            <div className="row">
              <div className="col-md-7"></div>
              <div className="col-md-5">
                <div className="block_text">
                  <h2 className="h2_text">
                    <span>
                      <Trans i18nKey="joinus_main_catch-phrase_html">
                        <strong>Ensemble,</strong>
                        <br />
                        <strong>Nous pourrons faire</strong>
                        <br />
                        <strong>tant de choses !</strong>
                      </Trans>
                    </span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* service single header end  */}
      <div className="service_detail rejoindre__middle">
        <div className="container">
          <div className="middle_content">
            <h2>{t("main:joinus_main_text_title")}</h2>
            <p>{t("main:joinus_main_text_p1")}</p>
            <p>
              {t("main:joinus_main_text_p2")}
              <br /> {t("main:joinus_main_text_p3")}
            </p>
            <p>{t("main:joinus_main_text_p4")}</p>
          </div>
        </div>
      </div>
      <div className="rejoindre-page-secound-section desktop_view">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              {/* <h2>{t('main:joinus_jobs-list_section_title' )}</h2> */}
              <h2 className="p-10">
                {t("main:joinus_no_jobs-list_section_smsg_description")}
              </h2>
            </div>
            {/* <div className="col-xl-4 col-lg-4 col-md-6 col-12">
          <ExtJoinUsCard
            category="devops"
            category_label={t('main:joinus_main_devops-category_title' )}
            job_offer_label={t('main:joinus_main_job-opportunity_label')}
            job_name={t('main:joinus_job-description_devops-joboffer-title_txt')}
            job_name_fragment_1={t('main:joinus_job-description_devops-joboffer-title_p1')}
            job_name_fragment_2={t('main:joinus_job-description_devops-joboffer-title_p2')}
            link_seo_title={`${t('main:global_main_know-more-on-position_link')} ${t('main:joinus_job-description_devops-joboffer-title_txt')}`}
            link_seo_btn={t('main:joinus_main_know-more_btn')}
            link_target_url={NavigationHelper.useGeneratePageURL(activeLanguage, 'job', 'j202203001')}
            job_description={t('main:joinus_jobs-list_k8s-expert-job-desc_txt')}
          />
        </div> */}
            {/* <div className="col-xl-4 col-lg-4 col-md-6 col-12" style={{margin: 'auto'}}>
        <ExtJoinUsCard
            category="dev"
            category_label={t('main:joinus_main_dev-category_title' )}
            job_offer_label={t('main:joinus_main_job-opportunity_label')}
            job_name={t('main:joinus_job-description_cloud-native-dev-joboffer-title_txt')}
            job_name_fragment_1={t('main:joinus_job-description_cloud-native-dev-joboffer-title_p1')}
            job_name_fragment_2={t('main:joinus_job-description_cloud-native-dev-joboffer-title_p2')}
            link_seo_title={`${t('main:global_main_know-more-on-position_link')} ${t('main:joinus_job-description_cloud-native-dev-joboffer-title_txt')}`}
            link_seo_btn={t('main:joinus_main_know-more_btn')}
            link_target_url={NavigationHelper.useGeneratePageURL(activeLanguage, 'job', 'j202203002')}
            job_description={t('main:joinus_jobs-list_dev-cloud-nat-job-desc_txt')}
          />
        </div> */}
            {/* <div className="col-xl-4 col-lg-4 col-md-6 col-12">
        <ExtJoinUsCard
            category="cloud"
            category_label={t('main:joinus_main_cloud-category_title' )}
            job_offer_label={t('main:joinus_main_job-opportunity_label')}
            job_name={t('main:joinus_job-description_cloud-architect-aws-joboffer-title_txt')}
            job_name_fragment_1={t('main:joinus_job-description_cloud-architect-aws-joboffer-title_p1')}
            job_name_fragment_2={t('main:joinus_job-description_cloud-architect-aws-joboffer-title_p2')}
            link_seo_title={`${t('main:global_main_know-more-on-position_link')} ${t('main:joinus_job-description_cloud-architect-aws-joboffer-title_txt')}`}
            link_seo_btn={t('main:joinus_main_know-more_btn')}
            link_target_url={NavigationHelper.useGeneratePageURL(activeLanguage, 'job', 'j202203003')}
            job_description={t('main:joinus_jobs-list_aws-architect-job-desc_txt')}
          />
        </div> */}
          </div>
        </div>
      </div>
      <div className="rejoindre-page-secound-section  mobile_view">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2>{t("main:joinus_jobs-list_section_title")}</h2>
            </div>
          </div>
          {/* <div className="row nous_slider"> */}
          <div className="nous_slider">
            <Slider {...job_offers_slider_settings}>
              {/* <div className="col-12">
        <ExtJoinUsCard
            category="devops"
            category_label={t('main:joinus_main_devops-category_title' )}
            job_offer_label={t('main:joinus_main_job-opportunity_label')}
            job_name={t('main:joinus_job-description_devops-joboffer-title_txt')}
            job_name_fragment_1={t('main:joinus_job-description_devops-joboffer-title_p1')}
            job_name_fragment_2={t('main:joinus_job-description_devops-joboffer-title_p2')}
            link_seo_title={`${t('main:global_main_know-more-on-position_link')} ${t('main:joinus_job-description_devops-joboffer-title_txt')}`}
            link_seo_btn={t('main:joinus_main_know-more_btn')}
            link_target_url={NavigationHelper.useGeneratePageURL(activeLanguage, 'job', 'j202203001')}
            job_description={t('main:joinus_jobs-list_k8s-expert-job-desc_txt')}
          />
        </div> */}
              {/* <div className="col-12">
        <ExtJoinUsCard
            category="dev"
            category_label={t('main:joinus_main_dev-category_title' )}
            job_offer_label={t('main:joinus_main_job-opportunity_label')}
            job_name={t('main:joinus_job-description_cloud-native-dev-joboffer-title_txt')}
            job_name_fragment_1={t('main:joinus_job-description_cloud-native-dev-joboffer-title_p1')}
            job_name_fragment_2={t('main:joinus_job-description_cloud-native-dev-joboffer-title_p2')}
            link_seo_title={`${t('main:global_main_know-more-on-position_link')} ${t('main:joinus_job-description_cloud-native-dev-joboffer-title_txt')}`}
            link_seo_btn={t('main:joinus_main_know-more_btn')}
            link_target_url={NavigationHelper.useGeneratePageURL(activeLanguage, 'job', 'j202203002')}
            job_description={t('main:joinus_jobs-list_dev-cloud-nat-job-desc_txt')}
          />
        </div> */}
              {/* <div className="col-12">
        <ExtJoinUsCard
            category="cloud"
            category_label={t('main:joinus_main_cloud-category_title' )}
            job_offer_label={t('main:joinus_main_job-opportunity_label')}
            job_name="Cloud Architect AWS"
            job_name_fragment_1="Cloud Architect"
            job_name_fragment_2="AWS"
            link_seo_title={`${t('main:global_main_know-more-on-position_link')} ${t('main:joinus_job-description_cloud-architect-aws-joboffer-title_txt')}`}
            link_seo_btn={t('main:joinus_main_know-more_btn')}
            link_target_url={NavigationHelper.useGeneratePageURL(activeLanguage, 'job', 'j202203003')}
            job_description={t('main:joinus_jobs-list_aws-architect-job-desc_txt')}
          />
        </div> */}
            </Slider>
          </div>
        </div>
      </div>

      {/* main content end */}
    </Layout>
  );
};

export default JoinUsPage;
