import React from "react"
import OffersHelper from "../helpers/offers"


export default function JoinUsListBoxCard(props) {

    const jobOfferColorsObj = OffersHelper.getJobCardColors(props.category)
    return (
        <>
            <div className={`service-page-box-row cat_${props.category}`}>
            <div className="service-page-box-row-img">
              <a href={props.link_target_url} title={props.link_seo_title}>
                <div className="service-page-box-row-img-first">
                  <img
                    className="bg_img_job"
                    src={jobOfferColorsObj.cover}
                    alt="top-bg"
                  />
                  <div className="first-img-text">
                    <span>
                      <small style={jobOfferColorsObj.style}>{props.job_offer_label}:</small>
                      <br />
                      {props.job_name_fragment_1}
                      <br />
                      <b>{props.job_name_fragment_2}</b>
                      {props.job_name_fragment_3 ? <br /> : ""}
                      {props.job_name_fragment_3}
                      {props.job_name_fragment_4 ? <br /> : ""}
                      {props.job_name_fragment_4}
                    </span>
                  </div>
                </div>
              </a>
            </div>
            <div className="service-page-box-row-text">
              <h4 style={jobOfferColorsObj.style}>{props.category_label}</h4>
              <h3>
                <a href={props.link_target_url} title={props.link_seo_title}>
                  {props.job_name}
                </a>
              </h3>
              <p>
                {props.job_description}
              </p>
            </div>
            <a
              href={props.link_target_url}
              className="btn"
              title={props.link_seo_title}
            >
              {props.link_seo_btn}
            </a>
          </div>
        </>
  )
}
